<template>
  <v-card
    class="pa-6 d-flex flex-column"
    style="gap: 20px; height: 100%; background: var(--v-background_light-base)"
    flat
    rounded="xl"
  >
    <div class="d-flex align-center">
      <div
        class="title-1 font-weight-bold onSurface_dark--text"
        style="width: 80px"
      >
        {{ $t("mypage.orders") }}
      </div>
      <v-spacer />
      <div v-if="fullscreen" class="d-flex">
        <v-text-field
          v-model="searchText"
          style="min-width: 140px; max-width: 280px"
          class="font-weight-regular body-2"
          flat
          dense
          hide-no-data
          hide-details
          solo
          background-color="surface"
          label="주문명"
          @keydown.enter.prevent="
            (searchText != searchedText || searchText == '') && orderSearch()
          "
        >
          <template #append>
            <v-btn
              icon
              :color="
                searchText != searchedText || searchText == '' ? 'primary' : ''
              "
              @click="orderSearch"
              small
              :disabled="searchBtnLoading"
            >
              <v-progress-circular
                indeterminate
                v-if="searchBtnLoading"
                size="20"
                width="3"
                color="primary"
              />
              <v-icon v-else> mdi-magnify </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <div v-else class="d-flex" @click="$router.push('/mypage/orders')">
        <div class="subtitle-1 font-weight-bold my-auto">
          {{ $t("mypage.see_more") }}
        </div>
        <v-icon class="my-auto" size="20"> mdi-chevron-right </v-icon>
      </div>
    </div>
    <div class="d-flex flex-column" style="gap: 12px; height: 100%">
      <div
        v-for="o in orderData"
        :key="o.id"
        class="d-flex flex-column pa-3"
        style="
          height: 120px;
          border-radius: 12px;
          outline: 1px solid var(--v-background_normal-base);
          background: var(--v-primary_light-base);
        "
        @click="openChat(o)"
      >
        <div class="d-flex subtitle-2 onSurface_light--text">
          <div>
            {{ o.workspace }}
          </div>
          <v-spacer />
          <div>{{ o.createdAt | moment("YYYY.MM.DD") }}</div>
        </div>
        <v-spacer />
        <div class="d-flex">
          <div
            class="title-1 font-weight-bold primary--text"
            style="white-space: nowrap"
          >
            {{ o.purpose }}
          </div>
          <v-spacer />
          <div class="title-1 font-weight-bold onSurface_dark--text">
            {{ o.productCost ? o.productCost.toLocaleString() : "0" }}
          </div>
          <div
            class="subtitle-1 font-weight-bold onSurface_dark--text my-auto ml-1"
          >
            {{ $i18n.locale == "ko" ? "원" : "&#36;" }}
          </div>
        </div>
        <v-spacer />
        <div class="d-flex subtitle-2 onSurface_light--text">
          <v-progress-linear
            :value="progressValue(o.status)"
            color="primary"
            height="12"
            style="border-radius: 6px"
          />
        </div>
        <v-spacer />
        <div class="d-flex title-2 font-weight-bold onSurface_normal--text">
          <div>
            {{ o.file.length }}
            {{ $i18n.locale == "ko" ? "개 파일" : "Files" }}
          </div>
          <v-spacer />
          <div>{{ getStatus(o.status) }}</div>
        </div>
      </div>
      <div v-if="fullscreen" class="d-flex pa-3" style="height: 120px">
        <div class="ma-auto subtitle-2 onSurface_light--text">
          <v-btn text @click="loadMoreOrders()">더보기</v-btn>
        </div>
      </div>
      <v-spacer />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MyPage-OrderCard",
  props: { userData: Object, fullscreen: Boolean },
  data() {
    return {
      searchText: "",
      searchedText: "",
      searchBtnLoading: false,
      orderData: [],
      lastVisible: null, // 마지막으로 가져온 문서
      loading: false, // 중복 로딩 방지 플래그
    };
  },
  mounted() {
    if (this.userData.uid) {
      this.orderData = [];
      this.getOrderData(); // 페이지 초기 로드
    }
  },
  watch: {
    "userData.uid"(newUid) {
      if (newUid) {
        this.getOrderData(); // uid가 존재할 때만 실행
      }
    },
  },
  methods: {
    getOrderData(initialLoad = true) {
      if (this.loading) return; // 중복 호출 방지
      this.loading = true;
      const orderDB = this.$firebase.firestore().collection("orders");
      let query = orderDB
        .where("uid", "==", this.userData.uid)
        .orderBy("createdAt", "desc")
        .limit(this.fullscreen ? 5 : 3);

      if (this.lastVisible && !initialLoad) {
        query = query.startAfter(this.lastVisible);
      }

      query.get().then((snapshot) => {
        if (!snapshot.empty) {
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }

        snapshot.forEach((doc) => {
          const item = doc.data();
          if (item.createdAt) item.createdAt = item.createdAt.toDate();
          if (item.updatedAt) item.updatedAt = item.updatedAt.toDate();
          if (item.paidAt) item.paidAt = item.paidAt.toDate();
          if (item.finishedAt) item.finishedAt = item.finishedAt.toDate();
          this.orderData.push(item);
        });

        this.loading = false; // 로딩 완료
      });
    },
    loadMoreOrders() {
      this.getOrderData(false); // false로 전달하여 추가 데이터를 로드
    },
    progressValue(status) {
      switch (status) {
        case 0:
          return (1 / 6) * 100;
        case 1:
          return (2 / 6) * 100;
        case 2:
          return (6 / 6) * 100;
        case 3:
          return (3 / 6) * 100;
        case 4:
          return (4 / 6) * 100;
        case 5:
          return (5 / 6) * 100;
        case 6:
          return (6 / 6) * 100;
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return this.$i18n.locale == "ko" ? "접수 완료" : "File Uploaded";
        case 1:
          return this.$i18n.locale == "ko" ? "파일 검토" : "File Reviewing";
        case 2:
          return this.$i18n.locale == "ko" ? "상담 완료" : "Finished";
        case 3:
          return this.$i18n.locale == "ko" ? "작업 대기" : "Prepare Print";
        case 4:
          return this.$i18n.locale == "ko" ? "작업 진행" : "Printing";
        case 5:
          return this.$i18n.locale == "ko" ? "배송 대기" : "Post Processing";
        case 6:
          return this.$i18n.locale == "ko" ? "작업 완료" : "Shipped";
      }
    },
    openChat(o) {
      if (o.channelTalk) window.ChannelIO("openChat", o.channelTalk.chat.id);
      this.$router.push({ path: "/mypage/orders", query: { id: o.id } });
    },
    orderSearch() {
      this.searchBtnLoading = true;
      this.$axios
        .get("orders/", {
          params: {
            searchText: this.searchText,
            uid: this.userData.uid,
          },
        })
        .then((v) => {
          if (v) {
            this.orderData = [];
            const data = v.data;
            data.forEach((d) => {
              this.orderData.push(d);
            });
          }
          this.searchBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-progress-linear,
::v-deep .v-progress-linear__background {
  background: var(--v-background_dark-base) !important;
  opacity: 1 !important;
}
::v-deep .v-progress-linear__determinate {
  background: linear-gradient(to right, #36d1dc, #5b86e5) !important;
  border-radius: 6px !important;
}
</style>
